











import { Component, Vue } from 'vue-property-decorator'
import landing from '../root/landing.vue'
import navigation from '@/views/common/navigation/web.vue'

@Component({
  components: {
    landing, navigation
  }
})

export default class WebLayout extends Vue {
}
