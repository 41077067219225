






import { Component, Vue } from 'vue-property-decorator'

import { mapActions, mapGetters } from 'vuex'
import MobileLayout from '@/views/layouts/MobileLayout.vue'
import WebLayout from '@/views/layouts/WebLayout.vue'
import isMobile from 'ismobilejs'

interface CurrentUserInterface {
  role?: string
}

@Component({
  components: { MobileLayout, WebLayout },
  computed: {
    ...mapGetters({
      currentUser: 'current_user',
      isCoach: 'isCoach',
      isPupil: 'isPupil'
    })
  },
  methods: {
    ...mapActions({
      getUser: 'getUser'
    })
  }
})

export default class RootIndex extends Vue {
  layout = 'WebLayout'
  isCoach!: Boolean
  isPupil!: Boolean
  currentUser!: CurrentUserInterface
  redirectPath = ''

  getUser!: () => any

  mounted () {
    this.getUser().then(this.initialCallback)

    if (isMobile(window.navigator).any) {
      this.layout = 'MobileLayout'
    }
  }

  get dashboardPageName () {
    return `${this.currentUser.role}_index`
  }

  initialCallback () {
    if (this.$route.name !== 'root_page') { return }

    if (this.isCoach) {
      this.redirectPath = 'pupils_index'
    } else if (this.isPupil) {
      this.redirectPath = 'pupil_index'
    }

    if (this.redirectPath) { this.$router.push({ name: this.redirectPath }) }
  }
}
