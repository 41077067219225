














































import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'

interface CurrentUserInterface {
  role?: string
}

@Component({
  methods: {
    ...mapActions({
      exit: 'exit'
    })
  },
  computed: {
    ...mapGetters({
      currentUser: 'current_user',
      isCoach: 'isCoach',
      isPupil: 'isPupil'
    })
  }
})

export default class NavigationComponent extends Vue {
  currentUser!: CurrentUserInterface
  isCoach!: Boolean
  isPupil!: Boolean

  exit!: () => any

  get dashboardPageName () {
    return `${this.currentUser.role}_index`
  }

  doExit () {
    this.exit().then(() => {
      window.location.href = '/'
    }).catch(() => {
      window.location.href = '/'
    })
  }
}
