<template lang="pug">
  .landing
    .landing__header
      .landing__logo
        img.img-fluid(src="@/assets/landing/logo.png" srcset="@/assets/landing/logo@2.png 2x")

      .landing__welcome
        p Добро пожаловать в закрытый клуб тренеров со всего мира.
        p Эта уникальная платформа позволяет удобно и качественно работать с клиентами из любой точки мира.

      .landing__buttons
        a.r-coach-btn.mr-3.text-uppercase(href="#contacts") Напишите нам
        a.r-coach-btn.text-uppercase(href="#about") О нас

    .dot-switchers
      .dot-switchers__dot.dot-switchers__dot-red
      .dot-switchers__dot.dot-switchers__dot-gray
      .dot-switchers__dot.dot-switchers__dot-gray

    .landing__body(id="about")
      h1.landing__title.text-uppercase О нас
      span.landing__understroke

      .landing-body-item.w-100
        .landing-body-item__header.col-9.col-lg-10.offset-3.offset-lg-2 профессионализм - твоё отличие

        .landing-body-item__body.w-100
          .col-3.col-lg-2.d-flex.justify-content-end
            img.img-fluid.landing-body-item__img(src="@/assets/landing/medal.png" srcset="@/assets/landing/medal@2.png 2x")

          .landing-body-item__text.col-9.col-lg-10
            | Приложение, заточенное под тебя и твоих клиентов во всех аспектах: тренировки, питание, антропометрия, связь 24/7 из любой точки мира, - всё в одном приложении.

      .landing-body-item.w-100
        .landing-body-item__header.col-9.col-lg-10.offset-3.offset-lg-2 создай личный бренд

        .landing-body-item__body.w-100
          .col-3.col-lg-2.d-flex.justify-content-end
            img.img-fluid.landing-body-item__img(src="@/assets/landing/todo_list.png" srcset="@/assets/landing/todo_list@2.png 2x")
          .landing-body-item__text.col-9.col-lg-10
            | Больше нет необходимости объяснять технику выполнения упражнений, достаточно добавить видео из галереи или загрузить свое. Стань первым в своём деле!

      .landing-body-item.w-100
        .landing-body-item__header.col-9.col-lg-10.offset-3.offset-lg-2 твоя игра по твоим правилам

        .landing-body-item__body.w-100
          .col-3.col-lg-2.d-flex.justify-content-end
            img.img-fluid.landing-body-item__img(src="@/assets/landing/timer.png" srcset="@/assets/landing/timer@2.png 2x")

          .landing-body-item__text.col-9.col-lg-10
            | Изменяй программу тренировок каждый день или составь рабочую базу, - выбор за тобой! Персональный подход к каждому - от юниора до профи!

      .landing-body-item.w-100
        .landing-body-item__header.col-9.col-lg-10.offset-3.offset-lg-2 в твоей команде твоё будущее

        .landing-body-item__body.w-100
          .col-3.col-lg-2.d-flex.justify-content-end
            img.img-fluid.landing-body-item__img(src="@/assets/landing/globe.png" srcset="@/assets/landing/globe@2.png 2x")

          .landing-body-item__text.col-9.col-lg-10
            | Ты сам добавляешь в команду нужных людей. Твои клиенты оставляют отзывы о работе с тренером и приводят друзей и знакомых. Стань лучшим в своём деле!

      .landing-body-item.w-100
        .landing-body-item__text.text-center Мы - команда тренеров, заряженных на результат!
        .landing-body-item__text.text-center Присоединяйся к нам!

      .landing-body-item.w-100(id="contacts")
        .landing-body-item__text.text-left По всем вопросам
        .landing-body-item__text.text-left.mt-1
          a(href="https://api.whatsapp.com/send?phone=79521545662") +7 952 154 56 62 - Денис
        .landing-body-item__text.text-left
          a(href="https://api.whatsapp.com/send?phone=79234470992") +7 923 447 09 92 - Надежда

    //.landing__footer
      //.landing__footer-icons
        //img.landing__footer-icon(src="@/assets/landing/instagram.svg")
        //img.landing__footer-icon(src="@/assets/landing/fb.svg")
        //img.landing__footer-icon(src="@/assets/landing/google_plus.svg")
</template>

<script>
export default {}
</script>
