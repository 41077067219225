














import { Component, Vue } from 'vue-property-decorator'
import landing from '../root/landing.vue'
import navigation from '@/views/common/navigation/mobile.vue'

@Component({
  components: {
    landing, navigation
  }
})

export default class MobileLayout extends Vue {
  get landing (): Boolean {
    return this.$route.name === 'root_page'
  }
}
